@import "../../App";

$checkout-blue: rgb(43, 115, 176);

.checkout-container {
  @include fat-font;
}

.order-summary {
  background-color: rgb(245, 245, 245);
  padding: 18px 14px;
  border-bottom: 1px solid #e0e0e0;

  .order-summary-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
  }

  h3 {
    color: $checkout-blue;
    height: 18px;
    line-height: 18px;
    font-size: 14px;
    text-align: left;
    margin: 0;

    svg {
      fill: none;
      stroke: currentColor;
      height: 18px;
      width: 18px;
      margin-right: 6px;
      vertical-align: bottom;
    }
  }

  .order-summary-price {
    font-weight: 500;
    color: $checkout-blue;
    font-size: 17px;
  }

  .order-item {
    display: flex;
    align-content: center;
    justify-content: space-between;
    text-align: left;
    font-size: 14px;
    padding: 14px 0;

    .order-item-photo {
      width: 64px;
      height: 64px;
      overflow: hidden;
      border-radius: 9px;
      background-color: black;
      position: relative;

      img {
        max-height: 100%;
        max-width: 70%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .order-item-name {
      flex-grow: 1;
      font-weight: bold;
      padding: 0 14px;
      height: 32px;
      line-height: 32px;
    }

    .order-item-price {
      height: 32px;
      line-height: 32px;
    }
  }

  .order-total {
    padding-top: 14px;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    .order-currency {
      font-weight: 300;
      font-size: 12px;
      margin-right: 10px;
    }
  }

}

.order-fill-in-form {
  padding: 0 14px 48px;
  text-align: left;
  display: block;
  position: relative;

  h3 {
    text-align: left;
    font-weight: bold;
  }

  .button-cta {
    margin: 48px 0 0;
  }

  input[type=email] {
    padding: 12px;
    background-color: white;
    border-radius: 5px;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    border: 1px solid rgb(230, 230, 230);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    font-size: 16px;
    color: rgb(48, 49, 61);
    display: block;
    width: calc(100% - 28px);
    margin-bottom: 42px;
  }

  .error-checkout {
    display: block;
    margin-top: 20px;
  }
}