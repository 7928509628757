.blog-hero-img {
  padding: 60px 0;

  img {
    max-width: 100%;
    height: 250px;
    display: block;
    margin: auto;
  }
}

.article-img-label {
  display: block;
  font-size: 12px;
  opacity: 0.66;
  text-align: center;
}

.blog-breadcrumbs {
  margin-top: 0;
  margin-bottom: 0;
}

.blog-article {
  padding: 0 25px;
  text-align: left;

  h1 {
    font-size: 21px;
    line-height: 1.1;
  }

  h2 {
    font-size: 20px;
    line-height: 1.1;
  }

  time {
    font-size: 14px;
    font-style: italic;
    color: rgb(51, 51, 51);
    opacity: 0.4;
  }

  p {
    font-size: 16px;
    line-height: 23px;
    padding-bottom: 16px;
  }
}

.article-image {

  padding: 25px 0;

  img {
    max-width: 100%;
  }
}