.menu-positioner {
  position: relative;
  top: -16px;
  left: -10px;
}

main {
  .hero {
    padding: 50px 20px;

    h3 {
      padding-top: 26px;
    }
  }

  .hero-img {
    background-color: black;
    padding: 110px 0;
    text-align: center;
    img {
      max-width: 300px;
    }
  }

  .our-product {

    .our-product-hero {
      padding: 40px 0;
    }

    h3 {
      padding-top: 10px;
    }
  }

  .homepage-cta {
    padding: 40px 0;
    text-align: center;

    input[type=button] {
      cursor: pointer;
    }
  }

  .learn-more {
    display: none;

    &.show {
      display: block;
    }
  }

  .our-products-description {
    text-align: left;
    margin-top: 120px;
    margin-bottom: 90px;

    h1 {
      text-align: left;
    }

    h3 {
      text-align: left;
      margin: 30px 0;
    }

    .product-price {
      display: block;
      font-size: 16px;
      color: rgb(51, 51, 51);
      font-weight: 700;
      margin: 5px 0 18px;
    }

    .product-link {
      display: inline-block;
      font-size: 16px;
      line-height: 22px;
      color: rgb(51, 51, 51);
      border-bottom: 1px solid rgb(51, 51, 51);
      text-decoration: none;
    }

  }
}