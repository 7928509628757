@import "../../App";

@mixin diagonal-pattern  {
  background-color: #ffffff;
  opacity: 0.8;
  background: repeating-linear-gradient( -45deg, #000000, #000000 3px, #ffffff 5px, #ffffff 9px );
}

.report-container {
  margin: 0 12px 150px;
  position: relative;

  .best .hero-text {
    margin: 150px 0 100px 0;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding: 0 0 14px 0;
      position: relative;
    }
  }

  .the-data {
    margin-top: 120px;
    h2 {
      font-size: 36px;
      font-weight: 500;
    }
  }

  .data-list {
    .photo-container p {
      position: relative;
      max-width: 100%;
      height: 300px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .bio-container {
      padding: 24px;
      border: 1px solid rgba(black, 0.1);
      position: relative;
      margin-bottom: 22px;
      font-style: italic;
      font-size: 20px;

      &:before {
        content: open-quote;
        font-size: 50px;
        position: absolute;
        top: -8px;
      }
      &:after {
        content: close-quote;
        font-size: 50px;
        position: absolute;
        bottom: -24px;
      }
    }

    .counter {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 1;
      font-weight: bold;
      color: black;
      padding: 0px 8px;
      background-color: white;
    }

    .data-list-item {
      margin-top: 150px;
    }

    .bigo-bar-fauxull {
      position: absolute;
      background-color: black;
      height: 20px;
      border-radius: 20px;
      width: 100%;
      @include diagonal-pattern;

      opacity: 0.15!important;
    }

    .bigo-bar {
      position: relative;
      background-color: rgba(black, 0.5);
      height: 20px;
      border-radius: 20px;
      //@include diagonal-pattern;
    }

    .bigo-bar-info {
      display: flex;
      justify-content: space-between;
      padding-top: 4px;
      font-weight: 900;
      font-size: 16px;

      .bigo-bar-info-type {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  .notes {
    text-align: left;
    margin: 0;

    h4 {
      margin: 28px 0 14px;
    }
    .note-container {
      display: inline-block;
      margin: 0 18px 0 0;

      .note {
        font-size: 16px;
        border: 1px solid black;
        border-radius: 20px;
        padding: 2px 16px;
      }

      .note-x {
        margin: 0 6px;
        font-weight: 200;
      }

      .note-times {
        font-weight: 900;
      }
    }
  }

  .sample-watermark {
    position: absolute;
    font-size: 40px;
    padding: 4px;
    border: 12px solid rgba(black, .7);
    text-transform: uppercase;
    font-weight: 900;
    opacity: .3;
    top: -80px;
    right: 0;
    transform: rotate(25deg);
    @include fat-font;
  }
}
