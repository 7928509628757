.menu-container {
  position: absolute;
  right: -44px;

  &.menu-active {
    z-index: 100;
  }

  .menu-items {
    z-index: 99;
    position: fixed;
    left: 0;
    right: 0;
    top: 10%;
    max-width: 780px;
    margin: auto;
    text-align: left;
    opacity: 0;
    animation: showHide 0.3s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    list-style-type: none;
    padding: 0 0 0 30px;
    text-shadow:
            -1px -1px 0 rgba(#fff, 1),
            1px -1px 0 rgba(#fff, 1),
            -1px 1px 0 rgba(#fff, 1),
            1px 1px 0 rgba(#fff, 1);

    li {
      cursor: pointer;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 50px;
      -webkit-tap-highlight-color: transparent;

      h4:first-of-type {
        margin-top: 0;
      }

      .menu-subtext {
        font-size: 16px;
        font-weight: 300;

        span {
          text-decoration: underline;
        }

        input[type=email] {
          display: inline-block;
          border: 2px solid black;
          font-size: 18px;
          padding: 8px;
          min-width: 250px;
          margin-right: 12px;
          margin-bottom: 8px;
        }

        input[type=button] {
          display: inline-block;
          vertical-align: baseline;
          background-color: white;
          cursor: pointer;
          border: 2px solid black;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          padding: 8px;
          text-transform: uppercase;
          -webkit-tap-highlight-color: transparent;
        }
      }
    }
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation-name: backdropBlur;
    animation-duration: .3s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  svg {
    height: 74px;
    position: absolute;
    width: 74px;
    display: block;
  }

  .plate {
    height: 80px;
    width: 80px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .burger {
    filter: url(#gooeyness);
  }

  .menu-x {
    transform: scale(0);
    transition: transform 400ms;
  }

  .menu-line {
    fill: none;
    stroke: black;
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform-origin: 50%;
    transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms;
  }

  .menu-x .menu-line {
    stroke-width: 5.5px;
  }

  /* Die vierte teller */
  .plate4 .menu-x {
    transition: transform 400ms;
  }

  .plate4 .menu-line {
    transform-origin: 50%;
    transition: transform 400ms 100ms;
  }

  .active.plate4 .menu-line {
    transition: transform 400ms;
  }

  .active.plate4 .menu-line1 {
    transform: translateX(18px) translateY(-3px) rotate(-45deg) scale(.7);
  }

  .active.plate4 .menu-line2 {
    transform: translateX(-18px) translateY(-3px) rotate(45deg) scale(.7);
  }

  .active.plate4 .menu-line3 {
    transform: translateY(0px) rotate(45deg) scale(.7);
  }

  .active.plate4 .menu-line4 {
    transform: translateY(0px) rotate(-45deg) scale(.7);
  }

  .active.plate4 .menu-line5 {
    transform: translateX(18px) translateY(3px) rotate(45deg) scale(.7);
  }

  .active.plate4 .menu-line6 {
    transform: translateX(-18px) translateY(3px) rotate(-45deg) scale(.7);
  }

  .active.plate4 .menu-x {
    transition: transform 400ms 100ms;
    transform: scale(1);
  }

}


@keyframes backdropBlur {
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(7px);
  }
}

@keyframes showHide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}