.thanks-container {
  margin: 40px 0 120px;
  text-align: left;

  h2 {
    margin: 30px 25px 40px;
  }

  p {
    margin: 30px 25px;
    font-size: 15px;
  }

  .thanks-link {
    font-size: 15px;
    line-height: 25px;
    text-decoration: none;
    border-bottom: 1px solid rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    margin: 0 25px;
  }
}