
.testimonials-title {
  font-size: 24px;
}

.testimonials-slide {
  padding: 25px;
  vertical-align: baseline;
  text-align: left;

  .testimonials-name {
    font-size: 17px;
    margin: 0;
    padding: 0;
    height: 66px;
    display: inline-block;
  }

  .testimonials-photo {
    vertical-align: middle;
    width: 66px;
    height: 66px;
    margin: 0 25px 0 0;
    background-size: cover;
    border-radius: 9999px;
    display: inline-block;
  }

  .testimonial-text {
    margin: 27px 0 37px;
    font-size: 20px;
  }

  h4 {
    display: inline-block;
  }
}

.carousel-root .carousel .control-dots {

  .dot {
    box-shadow: none;
    background-color: black;
  }
}

.carousel-status {
  display: none;
}

.testimonials-container {
  margin: 60px 0 60px;
}