.breadcrumbs {
  margin: 40px 25px;
  text-align: left;
  font-size: 14.5px;
  line-height: 21px;

  .separator {
    display: inline-block;
    margin: 0 7px;
    opacity: 0.4;
  }

  a {
    color: rgb(51, 51, 51);
    text-decoration: none;
  }

  .current-page {
    opacity: 0.66;
  }
}

.product-description {
  margin: 0 25px;
  text-align: left;
  font-weight: 400;
  padding-bottom: 80px;

  h1 {
    margin-top: 32px;
    font-size: 22px;
  }

  .price {
    font-size: 19.5px;
    font-weight: 500;
  }

  .old-price {
    display: inline-block;
    margin-left: 20px;
    font-weight: 500;
    opacity: 0.66;
    font-size: 19.5px;
    text-decoration: line-through;
  }

  .product-description-text {
    padding-top: 40px;
    font-size: 17px;
    line-height: 27px;
  }

  .underline-product {
    border-bottom: 1px solid rgb(51, 51, 51);
  }

  h2 {
    margin-top: 32px;
  }

  .hero-img {
    margin: 0 -25px;
    img {
      max-width: 200px;
    }
  }

  .par-indent {
    padding-left: 40px;
    position: relative;

    &:before {
      content: "→";
      position: absolute;
      left: 16px;
      top: 0;
    }
  }
}

.get-started-form {
  border: 2px solid rgb(51, 51, 51);
  max-width: 300px;
  margin: 60px auto;
  display: flex;
  flex-direction: row;

  input[type=email] {
    border-witdh: 0px;
    flex-grow: 1;
    height: 50px;
    padding-left: 10px;
    font-size: 15px;
    color: rgb(51, 51, 51);
    border-width: 0;
  }

  input[type=submit] {
    font-size: 15px;
    color: rgb(51, 51, 51);
    background-color: transparent;
    border-width: 0;
    font-weight: bold;
    padding: 0 10px;
    border-left: 2px solid rgb(51, 51, 51);
  }
}