.profile-container {
  text-align: left;
  max-width: 500px;
  margin: auto;

  .carousel-container {
    position: relative;
    padding: 12px;
    border-radius: 0.5rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
    font-size: 0;

    .photos-container {
      overflow: hidden;
    }

    button {
      .arrow {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
      }

      .right {
        transform: rotate(-45deg);
      }

      .left {
        transform: rotate(135deg);
      }
    }
  }

  h3 {
    font-weight: 900;
    font-size: 34px;
    margin: 20px 0 0;
    text-align: left;
    display: inline-block;
  }

  .age {
    font-size: 24px;
  }

  .other-details {
    font-size: 18px;
    height: 30px;
    padding-top: 6px;

    svg {
      width: 30px;
      vertical-align: bottom;
    }

    span {
      height: 30px;
      line-height: 30px;
      padding-left: 8px;
    }
  }

  .bio {
    border-left: 16px solid black;
    padding-left: 8px;
    margin: 26px 0;
    font-size: 20px;
    font-weight: 200;
  }
}