body {
  font-family: "DM Sans";
  font-size: 22px;
  color: rgb(51, 51, 51);
}

@mixin fat-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fat-font {
  @include fat-font;
}

.app {
  text-align: center;
  max-width: 780px;
  margin: auto;
  overflow: hidden;
}

.announcement {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: center;
  z-index: 9999;
  font-size: 14.5px;
  background-color: black;
  color: white;
}

.app-header {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 30px 0 20px;
  border-bottom: 1px solid #e0e0e0;

  .logo-container {
    .app-logo {
      width: 90px;
    }
  }
}

h1 {
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
}

h2 {
  font-size: 23px;
  font-weight: 400;
}

h3 {
  font-size: 17px;
  font-weight: 500;
}

.button {
  font-size: 18px;
  color: rgb(51, 51, 51);
  background-color: transparent;
  font-weight: bold;
  padding: 6px 30px;
  height: 50px;
  line-height: 50px;
  border: 2px solid rgb(51, 51, 51);
}

.button-cta {
  display: inline-block;
  min-width: 200px;
  background-color: rgba(51, 51, 51, 1);
  color: white;
  text-align: center;
  //padding: 20px 25px;
  @include fat-font;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.horizontal-separator {
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
}

footer {
  background-color: black;
  padding: 50px 25px 25px;
  color: white;

  .footer-h2 {
    text-align: left;
    font-size: 31px;
    margin-bottom: 50px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      text-align: left;
      padding-bottom: 15px;

      a {
        color: white;
        text-decoration: none;
        font-size: 15px;
        border-bottom: 1px solid white;
      }

    }
  }

  .footer-copyright {
    font-size: 15px;
    padding: 100px 0 50px;
    text-align: left;

    a {
      color: white;
      text-decoration: none;
    }
  }
}

a {
  color: unset;
  text-decoration: unset;
}

button {
  -webkit-tap-highlight-color: transparent;
}